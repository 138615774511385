@if (!environment.production) {
  <app-stage-indicator />
}
<div class="dimilar-theme tw-flex tw-min-h-screen tw-flex-col tw-justify-between" xmlns="http://www.w3.org/1999/html">
  <div>
    <msa-header />

    <!-- we need this layer to place absolute elments inside the page below the headers -->
    <div
      class="content-wrapper tw-relative tw-w-full"
      [class.tw-px-0]="fullPageWidth()"
      [class.d:tw-px-0]="fullPageWidth()"
    >
      <router-outlet />
    </div>
  </div>

  <div>
    <msa-bottom-bar />
    <msa-footer>
      @if (showRichContent$ | async) {
        <msa-rich-footer />
      }
    </msa-footer>
  </div>
</div>
