import {NgModule} from '@angular/core';
import {RouterModule, ROUTES, Routes} from '@angular/router';
import {NotFoundComponent} from 'src/app/shared-module/components/not-found/not-found.component';

import {UnauthorizedComponent} from '@shared/shared-module/components/unauthorized/unauthorized.component';
import {agreementGuard} from '@shared/shared-module/guards/agreement.guard';
import {environment} from 'src/environments/environment';
import {FeatureFlagOverviewComponent} from './shared-module/components/feature-flag-overview/feature-flag-overview.component';
import {UserNotLoadedComponent} from './shared-module/components/user-not-loaded/user-not-loaded.component';
import {authorizationGuard} from './shared-module/guards/authorization.guard';
import {popupsGuard} from '@shared/shared-module/guards/popupsGuard';

const routes: Routes = [
  {
    path: 'feature-flags',
    component: FeatureFlagOverviewComponent,
    canActivate: [authorizationGuard(), agreementGuard(), popupsGuard]
  },
  {
    path: 'admin-query',
    loadChildren: () => import('../../projects/admin-query/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'information',
    loadChildren: () => import('../../projects/person-data/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'component-overview',
    loadChildren: () => import('../../projects/component-overview/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('../../projects/notifications/src/app/notifications.module').then(m => m.NotificationsModule)
  },
  {
    path: '',
    loadChildren: () => import('../../projects/landing-page/src/app/app.module').then(m => m.AppModule),
    data: {hideNavigationBar: true, hideSimpleContactFooter: true}
  },
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'oidc-callback', redirectTo: ''}, // keeping URL for now, to avoid registering new valid redirect_urls
  {path: 'user-not-loaded', component: UserNotLoadedComponent, data: {hideNavigationBar: true}},
  {path: '**', component: NotFoundComponent, data: {title: 'i18n.login.title'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        if (!environment.production) {
          routes.push({
            path: 'component-overview',
            loadChildren: () => import('../../projects/component-overview/src/app/app.module').then(m => m.AppModule)
          });
        }
        return routes;
      },
      multi: true
    }
  ]
})
export class AppRoutingModule {}
