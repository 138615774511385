{
  "name": "msa-portal-ui",
  "version": "1.6.2",
  "scripts": {
    "start": "npm run start:storybook & npm run start:angular",
    "start:angular": "npm run pretasks && ng serve",
    "start:storybook": "ng run msa-portal-ui:storybook --quiet --no-open",
    "copy-lib-assets": "mkdir -p dist && cp nginx.conf dist/nginx.conf && cp mime.types dist/mime.types",
    "build": "node ./build.js",
    "build-production": "npm run build production",
    "build:storybook": "ng run msa-portal-ui:build-storybook --test",
    "test:ci": "npm run test:unit",
    "test": "npm run test:unit; npm run test:components",
    "test:unit": "npm run pretasks; node --expose-gc --no-compilation-cache node_modules/.bin/jest --watchAll=false --coverage",
    "test:unit:watch": "npx jest --watchAll",
    "test:components": "npx cypress run --component",
    "lint": "npm run pretasks; ng lint",
    "prettier": "./node_modules/.bin/prettier --write **/{*.ts,*.html,*.scss,*.json,*.js}",
    "format": "npm run lint -- && npm run prettier",
    "pretasks": "npm run copy-lib-assets && npm run rest-api:msa-duty-service && npm run rest-api:msa-person-data && npm run rest-api:msa-person-data && npm run rest-api:msa-portal",
    "rest-api:msa-duty-service": "rimraf projects/admin-query/src/app/core/api/generated/msa-duty-service && HTTPS_PROXY=\"\" NODE_TLS_REJECT_UNAUTHORIZED='0' openapi-generator-cli generate --generator-key msa-duty-service",
    "rest-api:msa-person-data": "rimraf projects/person-data/src/app/core/api/generated/msa-person-data && NODE_TLS_REJECT_UNAUTHORIZED=0 openapi-generator-cli generate --generator-key msa-person-data",
    "rest-api:msa-portal": "rimraf src/app/core/api/generated/msa-portal && NODE_TLS_REJECT_UNAUTHORIZED=0 openapi-generator-cli generate --generator-key msa-portal",
    "rest-api:msa-notification": "rimraf projects/notification/src/app/core/api/generated/msa-notification && HTTPS_PROXY=\"\" NODE_TLS_REJECT_UNAUTHORIZED='0' openapi-generator-cli generate --generator-key msa-notification"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.3",
    "@angular/cdk": "^17.0.2",
    "@angular/common": "^17.1.3",
    "@angular/compiler": "^17.1.3",
    "@angular/core": "^17.1.3",
    "@angular/forms": "^17.1.3",
    "@angular/material": "^17.0.2",
    "@angular/material-moment-adapter": "^17.0.2",
    "@angular/platform-browser": "^17.1.3",
    "@angular/platform-browser-dynamic": "^17.1.3",
    "@angular/router": "^17.1.3",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@ngxs/storage-plugin": "^3.8.2",
    "@ngxs/store": "^3.8.2",
    "@storybook/addon-postcss": "^2.0.0",
    "angular-auth-oidc-client": "^17.0.0",
    "angularx-qrcode": "^17.0.1",
    "file-saver": "^2.0.5",
    "lodash": "^4.17.21",
    "material-icons": "^1.13.12",
    "ngx-cookie-service": "^17.1.0",
    "ngx-mask": "^17.0.7",
    "ngx-material-timepicker": "^13.1.1",
    "rxjs": "^7.8.1",
    "sha.js": "^2.4.11",
    "tslib": "^2.6.2",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.4",
    "@angular-eslint/builder": "^17.1.1",
    "@angular-eslint/eslint-plugin": "^17.1.1",
    "@angular-eslint/eslint-plugin-template": "^17.1.1",
    "@angular-eslint/schematics": "^17.1.1",
    "@angular-eslint/template-parser": "^17.1.1",
    "@angular/cli": "~17.2.0",
    "@angular/compiler-cli": "^17.1.3",
    "@angular/localize": "^17.1.3",
    "@compodoc/compodoc": "^1.1.24",
    "@cypress/angular-signals": "^1.0.0",
    "@ngxs/devtools-plugin": "^3.8.2",
    "@openapitools/openapi-generator-cli": "^2.13.1",
    "@storybook/addon-backgrounds": "^8.2.0-alpha.9",
    "@storybook/addon-docs": "^8.2.0-alpha.9",
    "@storybook/addon-essentials": "^8.2.0-alpha.9",
    "@storybook/addon-interactions": "^8.2.0-alpha.9",
    "@storybook/addon-links": "^8.2.0-alpha.9",
    "@storybook/addon-viewport": "^8.2.0-alpha.9",
    "@storybook/angular": "^8.2.0-alpha.9",
    "@storybook/blocks": "^8.2.0-alpha.9",
    "@storybook/test": "^8.2.0-alpha.9",
    "@types/file-saver": "^2.0.7",
    "@types/jest": "^29.5.10",
    "@types/lodash": "^4.14.202",
    "@types/sha.js": "^2.4.4",
    "@typescript-eslint/eslint-plugin": "^6.13.1",
    "@typescript-eslint/parser": "^6.21.0",
    "autoprefixer": "^10.4.19",
    "browserslist": "^4.23.0",
    "compression-webpack-plugin": "^11.0.0",
    "cypress": "^13.14.2",
    "cypress-mochawesome-reporter": "^3.8.2",
    "cypress-vite": "^1.5.0",
    "esbuild": "^0.21.5",
    "eslint": "^8.55.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-import-resolver-typescript": "^3.6.1",
    "eslint-plugin-boundaries": "^4.2.1",
    "eslint-plugin-import-x": "^0.5.3",
    "eslint-plugin-prettier": "^5.0.1",
    "eslint-plugin-rxjs": "^5.0.3",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-fail-on-console": "^3.1.2",
    "jest-preset-angular": "^14.1.0",
    "jest-sonar-reporter": "2.0.0",
    "lint-staged": "^15.2.0",
    "ng-lint-staged": "12.0.4",
    "ngx-translate-testing": "^7.0.0",
    "npx": "^10.2.2",
    "postcss": "^8.4.38",
    "postcss-import": "^16.1.0",
    "prettier": "^3.3.2",
    "prettier-plugin-organize-attributes": "^1.0.0",
    "prettier-plugin-tailwindcss": "^0.6.5",
    "storybook": "^8.2.0-alpha.9",
    "tailwindcss": "^3.4.4",
    "ts-mockito": "^2.6.1",
    "ts-node": "^10.9.1",
    "tty-browserify": "^0.0.1",
    "typescript": "5.2.2",
    "yargs": "^17.7.2"
  },
  "jestSonar": {
    "reportPath": "./coverage/sonarQube",
    "reportFile": "sqr.xml",
    "indent": 4,
    "sonar56x": true
  },
  "lint-staged": {
    "**/*.{js,ts,md,html,json}": [
      "prettier --write"
    ],
    "**/*.ts": [
      "ng-lint-staged lint --fix --"
    ]
  }
}
